import React from 'react';
import styles from './about.module.css';
import aboutLogo from '../../Images/my.png'

export const About = () => {
    return (
        <section className={styles.about} id="about">
            <div className={styles.aboutImg}>
                <img src={aboutLogo} alt="About me" />
            </div>

            <div className={styles.aboutContent}>
                <h2 className={styles.heading}>About<span> me</span></h2>
                <h3>Frontend Developer</h3>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate aperiam eligendi enim deleniti, ullam tenetur cumque corporis maiores sapiente quaerat ipsa? Atque perferendis quidem laudantium ducimus cumque fugit, asperiores id rerum, quod nesciunt voluptatibus consectetur nihil vel distinctio. Consequuntur dolorem praesentium sunt et cupiditate aut ipsum.</p>
                <a href="#" className={styles.btn}>Read More</a>
            </div>
        </section>
    );
};

