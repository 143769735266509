import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './detailPage.module.css';
import { HeaderDetail } from '../../Components/HeaderDetail/HeaderDetail';
import { LuExternalLink } from "react-icons/lu";
import { Footer } from '../../Components/Footer/footer';

export const DetailPage = () => {
    const { id } = useParams();
    const [project, setProject] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://zero.kg/api/project/detail/${id}?timestamp=${new Date().getTime()}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setProject(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    if (!project) {
        return <div>Loading...</div>;
    }

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % project.images.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + project.images.length) % project.images.length);
    };

    const handleTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (touchStart - touchEnd > 50) {
            nextSlide();
        }

        if (touchStart - touchEnd < -50) {
            prevSlide();
        }
    };

    return (
        <div>
            <HeaderDetail projectName={project.name} />
            <div className={styles.container}>
                <p className={styles.summary}>{project.summary}</p>
                <div 
                    className={styles.sliderContainer}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <div className={styles.slider}>
                        {project.images.map((image, index) => (
                            <div
                                key={image.id}
                                className={`${styles.slide} ${index === currentSlide ? styles.active : ''}`}
                            >
                                <img
                                    className={styles.img}
                                    src={`https://zero.kg/${image.filename}`}
                                    alt={project.name}
                                />
                                <a
                                    href={project.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`${styles.imageLink} ${project.link === "https://example.com" ? styles.hidden : ''}`}
                                >
                                    <LuExternalLink className={styles.navLink}/>
                                </a>
                            </div>
                        ))}
                    </div>
                    <button className={styles.prev} onClick={prevSlide}>&#10094;</button>
                    <button className={styles.next} onClick={nextSlide}>&#10095;</button>
                </div>
                <div className={styles.dContainer}>
                    <p className={styles.description}>{project.description}</p>
                </div>
                <a href={project.link} target="_blank" rel="noopener noreferrer" className={`${styles.link} ${project.link === "https://example.com/" ? styles.hidden : ''}`}><LuExternalLink className={styles.navLink}/></a>
            </div>
            <Footer />
        </div>
    );
};
