import React, { useEffect, useState } from 'react';
import styles from './portfolio.module.css';
import { FaExternalLinkAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';

export const Portfolio = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch('https://zero.kg/api/projects/')
            .then(response => response.json())
            .then(data => setProjects(data.projects))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <section className={styles.portfolio} id="portfolio">
            <h2 className={styles.heading}>Latest <span>Portfolio</span></h2>

            <div className={styles.portfolioContainer}>
                {projects.map((project) => (
                    <div className={styles.portfolioBox} key={project.id}>
                        <img src={project.images[0]?.file_path ? `https://zero.kg${project.images[0].file_path}` : './images/portfolio1.jpg'} alt={project.name} />
                        <div className={styles.portfolioLayer}>
                            <h4>{project.name}</h4>
                            <p>{project.summary}</p>
                            <Link to={`/detailpage/${project.id}`} ><FaExternalLinkAlt /> </Link>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};
