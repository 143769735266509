import React from 'react';
import styles from './footer.module.css';
import { FaArrowUp } from "react-icons/fa";

export const Footer = () => {

    return (
        <footer className={styles.footer}>
            <div className={styles.footerText}>
                <p>Copyright &copy; 2023 by Codehal | All Right Reserved</p>
            </div>
            <div className={styles.footerIconTop}>
                <a href="#"><i className='bx bx-up-arrow-alt'><FaArrowUp /></i></a>
            </div>
        </footer>
    );
};

export default Footer;
