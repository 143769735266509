import React from 'react';
import headerLogo from '../../Images/zero_logo_no_name-removebg-preview.png';
import styles from './HeaderDetail.module.css';
import { Link } from 'react-router-dom';

export const HeaderDetail = ({ projectName }) => {
  return (
    <div className={styles.headerDetail}>
      <div>
        <Link to={'/'}><img src={headerLogo} alt="Logo" className={styles.logo} /></Link>
      </div>
      <div>
        <h1 className={styles.title}>{projectName}</h1>
      </div>
      <div>
        <Link to={'/'} className={styles.nav}>на главную</Link>
      </div>
    </div>
  );
};
