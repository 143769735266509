import React from 'react';
import styles from './services.module.css';
import { FaCode } from "react-icons/fa";
import { FaPaintbrush } from "react-icons/fa6";
import { BiSolidBarChartAlt2 } from "react-icons/bi";

export const Services = () => {
    return (
        <section className={styles.services} id="services">
            <h2 className={styles.heading}>Our <span>Services</span></h2>

            <div className={styles.servicesContainer}>
                <div className={styles.servicesBox}>
                    <i className='bx bx-code-alt'><FaCode /></i>
                    <h3>Web Development</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat ab sint beatae animi incidunt adipisci rerum porro temporibus velit assumenda.</p>
                    <a href="#" className={styles.btn}>Read More</a>
                </div>

                <div className={styles.servicesBox}>
                    <i className='bx bxs-paint'><FaPaintbrush/></i>
                    <h3>Graphic Design</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat ab sint beatae animi incidunt adipisci rerum porro temporibus velit assumenda.</p>
                    <a href="#" className={styles.btn}>Read More</a>
                </div>

                <div className={styles.servicesBox}>
                    <i className='bx bx-bar-chart-alt'><BiSolidBarChartAlt2 /></i>
                    <h3>Digital Marketing</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat ab sint beatae animi incidunt adipisci rerum porro temporibus velit assumenda.</p>
                    <a href="#" className={styles.btn}>Read More</a>
                </div>
            </div>
        </section>
    );
};

