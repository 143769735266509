import React from 'react';
import styles from './home.module.css';
import logoUser from '../../Images/mi.png'
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";

export const Home = () => {
    return (
        <section className={styles.home} id="home">
            <div className={styles.homeContent}>
                <h3>Привет, мы являемся</h3>
                <h1>Разработчиками </h1>
                <h3>Программного  <span className={styles.frontend}>Обеспечения</span></h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At iure autem inventore laboriosam expedita dolorem, earum quasi.</p>
                <div className={styles.socialMedia}>
                    <a href="#"><i className='bx bxl-facebook'><FaFacebookF /></i></a>
                    <a href="#"><i className='bx bxl-twitter'><FaTwitter /></i></a>
                    <a href="https://instagram.com/zero.kg_software?igshid=MjEwN2IyYWYwYw==" target="_blank"><i className='bx bxl-instagram'><FaInstagram /></i></a>
                    <a href="#"><i className='bx bxl-linkedin'><FaLinkedinIn /></i></a>
                </div>
                <a href="#" className={styles.btn}>Download</a>
            </div>

            <div className={styles.homeImg}>
                <img src={logoUser} alt="Profile" />
            </div>
        </section>
    );
};

