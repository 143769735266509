import React, { useState } from 'react';
import styles from './header.module.css';
import headerLogo from '../../Images/zero_logo_no_name-removebg-preview.png';
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from 'react-router-dom';

export const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className={styles.header}>
            <Link to={'/'} className={styles.logoContainer}>
                <img src={headerLogo} alt="Logo" className={styles.logo} />
            </Link>

            <div className={styles.burger} onClick={toggleMenu}>
                {isMenuOpen ? <FaTimes /> : <FaBars />}
            </div>

            <nav className={`${styles.navbar} ${isMenuOpen ? styles.navOpen : ''}`}>
                <a href="#home" className={styles.active}>Home</a>
                <a href="#about">About</a>
                <a href="#services">Services</a>
                <a href="#portfolio">Portfolio</a>
                <a href="#contact">Contact</a>
            </nav>
        </div>
    );
};
