import React from 'react'
import {Home} from '../../Components/Home/home.jsx'
import {About} from '../../Components/About/about.jsx'
import {Services} from '../../Components/Services/services.jsx'
import {Portfolio} from '../../Components/Portfolio/portfolio.jsx'
import {Header} from '../../Components/Header/header.jsx'
import {Footer} from '../../Components/Footer/footer.jsx'
export const Main = () => {


    return (
        <div>
            <Header />
            <Home />
            <About />
            <Services />
            <Portfolio />
            <Footer />
        </div>
    )
}
