import './App.css';
import { Header } from './Components/Header/header';

import Footer from './Components/Footer/footer';
import { DetailPage } from './Pages/Detailpage/detailPage';
import { Route, Routes } from 'react-router';
import { Main } from './Pages/Main/main';

function App() {
  return (
    <div>
      {/* <Header /> */}
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path="/detailpage/:id" element={<DetailPage />} />
      </Routes>
    </div>
  );
}

export default App;
